<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Artigo</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="start"
        no-gutters
        class="pa-2"
      >
        <v-col
          class="d-flex"
          lg="2"
          md="2"
        >
          <v-checkbox
            v-model="articles.active"
            label="Ativo?"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="articles.author"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Autor *"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-select
            v-model="articles.unit"
            :items="unities"
            :rules="$rules.required"
            required
            label="Unidade *"
            dense
            outlined
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="articles.headline"
            :counter="200"
            outlined
            dense
            label="Título do Artigo *"
            :rules="$rules.required"
            required
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="articles.caption"
            outlined
            :counter="300"
            dense
            label="Subtítulo do Artigo *"
            :rules="$rules.required"
            required
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col class="d-flex pr-5">
          <v-select
            v-model="articles.galleryId"
            :items="galleries"
            item-text="name"
            item-value="id"
            chips
            label="Galerias"
            multiple
            outlined
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col class="d-flex pr-5">
          <v-file-input
            v-model="imagesUpload"
            outlined
            :images="imagesPreview"
            label="Clique para adicionar imagens do tamanho de 1024x768"
            prepend-icon="mdi-camera"
            accept="image/jpeg, image/png"
            @change="onFilesSelected"
          />
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialogCropImage"
        persistent
      >
        <v-card>
          <div>
            <v-card-title class="text-h5">
              Recorte da imagem para tamanho 1024x768
            </v-card-title>
          </div>
          <div>
            <v-card-text>
              Para melhor visualização da imagem no Informa Site, recomendamos
              utilizar o tamanho da imagem em 1024x768. <br>
              Utilize o "scroll" do mouse para ajustar a imagem.
              <br>
              Clique e segure na imagem para arrastar e posicionar.
            </v-card-text>
          </div>
          <div style="padding-left: 600px">
            <resize-image
              ref="image"
              :target-width="resizeImageParams.targetWidth"
              :target-height="resizeImageParams.targetHeight"
              :min-aspect-ratio-value1="resizeImageParams.minAspectRatioValue1"
              :min-aspect-ratio-value2="resizeImageParams.minAspectRatioValue2"
              :max-aspect-ratio-value1="resizeImageParams.maxAspectRatioValue1"
              :max-aspect-ratio-value2="resizeImageParams.maxAspectRatioValue2"
              :image-param="imagesPreview"
            />
          </div>
          <v-card-actions>
            <v-btn
              color="red darken-1"
              text
              @click="cancelCropImage()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="cropImageOnConfirm"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col>
        <gallery
          :images="imagesPreview"
          :rules="$rules.required"
          required
          @image-deleted="onDeleteImage"
        />
      </v-col>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col class="pr-5">
          <kosmo-editor
            v-model="articles.text"
            :max-length="5000"
            label="Texto do Artigo *"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-6"
      >
        <v-col class="pr-5">
          <v-file-input
            v-model="attcUpload"
            label="Clique para adicionar novos arquivos"
            multiple
            outlined
            show-size
            counter
            accept="application/pdf,application/vnd.ms-excel"
          >
            <template #selection="{ index, text }">
              <v-chip
                v-if="index < 4"
                class="white accent-4"
                label
              >
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 4"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ attcUpload.length - 4 }} arquivo(s)
              </span>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="submit"
        />
        <v-btn
          v-else
          color="primary"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import sanitize from 'sanitize-filename';
import ArticlesService from '../../../services/articles.service';
import GalleryService from '../../../services/gallery.service';
import { createFeaturedPhoto } from '../../../services/photos-service';
import { image } from '../../../services/upload-service';
import ResizeImage from '../../../components/shared/ResizeImage.vue';
import imageFormatter from '../../../utils/convert-image-to-file';

export default {
  name: 'ArticlesForm',
  components: {
    ResizeImage,
  },
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      unities: [
        'Cassems Sede',
        'Cassems Corumbá',
        'Cassems Aquidauana',
        'Cassems Coxin',
        'Cassems Ponta Porã',
        'Cassems Dourados',
        'Cassems Naviraí',
        'Hospital Campo Grande',
      ],
      galleries: [],
      articles: {
        id: '',
        active: false,
        author: '',
        unit: '',
        headline: '',
        caption: '',
        galleryId: '',
        text: '',
        attc: [],
      },
      attcUpload: [],
      linkFiles: [],
      resizeImageParams: {
        targetWidth: 1024,
        targetHeight: 768,
        minAspectRatioValue1: 4,
        minAspectRatioValue2: 3,
        maxAspectRatioValue1: 4,
        maxAspectRatioValue2: 3,
      },
      imagesPreview: [],
      imagesUpload: null,
      loading: false,
      dialogCropImage: false,
    };
  },
  async created() {
    console.log('PopulateWith:', this.populateWith);
 
 if(this.populateWith.id){
  
 }
 
    if (this.populateWith.id) {
    const selectedGalleries = this.populateWith.articlesGallery.map(
      ({ gallery }) => ({ name: gallery.name, id: gallery.id }),
    );
    this.articles = { ...this.populateWith };
    this.articles.galleryId = selectedGalleries;
    this.imagesPreview = this.articles?.featuredImage
      ? [this.articles?.featuredImage]
      : [];

    this.attcUpload = this.articles?.attc ?? [];
  }

  const data = await GalleryService.getGalleries();
  this.galleries = data.map(({ name, id }) => ({ name, id }));
},
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async checkFileAndUpload() {
      const uploadFilesPromises = this.attcUpload.map(async (item) => image(item));
      const uploadFilesResponse = await Promise.all(uploadFilesPromises);
      const arrayFilesLinks = uploadFilesResponse.map((item) => item[0]);
      this.articles = { ...this.articles, attc: arrayFilesLinks };
    },
    onFilesSelected(files) {
      if (!files) return;
      this.dialogCropImage = true;
      this.imagesPreview = [URL.createObjectURL(files)];
    },
    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      if (this.imagesUpload) {
        this.imagesUpload.splice(index, 1);
      }

      this.articles = { ...this.articles, link: null };
    },
    async checkImagesUploadAndCreatePhoto() {
      if (this.imagesUpload) {
        const imageType = imageFormatter.getMimeType(this.imagesPreview);
        const formattedTitle = sanitize(this.articles.headline);
        const imageFormatted = imageFormatter.convertImageToFile(
          this.imagesPreview,
          `${formattedTitle.substring(0, 10)}.${imageType}`,
        );

        const photo = await createFeaturedPhoto(imageFormatted);
        this.articles = { ...this.articles, link: photo.link };
      }
    },
    async updateArticles() {
      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileAndUpload();
        await ArticlesService.updateArticles({ ...this.articles, id: String(this.articles.id) });
        console.log(this.articles.id)
        console.log(typeof(this.articles.id));
        this.$emit('articles-edited', this.articles);
        this.$toast.success('Artigo editado com sucesso');
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    cropImageOnConfirm() {
      const { coordinates, canvas } = this.$refs.image.$refs.cropper.getResult();
      this.coordinates = coordinates;
      const imageToArray = canvas.toDataURL();
      this.imagesPreview = [imageToArray];
      this.dialogCropImage = false;
    },

    cancelCropImage() {
      this.dialogCropImage = false;
      this.imagesUpload = null;
      this.imagesPreview = [];
    },

    async submit() {
      const textExededMaxSize = this.articles.text.length > 5000;

      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      if (textExededMaxSize) {
        this.$toast.error('Limite de caracteres atingido no campo de texto!');
        return;
      }
      if (this.articles.id) {
        await this.updateArticles();
        return;
      }

      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileAndUpload();
        await ArticlesService.createArticles({ ...this.articles });
        this.$emit('articles-added', { ...this.articles });
        this.clearForm();
        this.$toast.success('Artigo cadastrado com sucesso');
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    clearForm() {
      this.$refs.form.reset();
      this.articles.text = null;
      this.articles.author = null;
      this.articles.headline = null;
      this.articles.headline = null;
      this.articles.caption = null;
      this.articles.galleryId = null;
      this.articles.attc = [];
      this.articles.active = false;
      this.articles.unit = null;
      this.imagesPreview = [];
      this.attcUpload = [];
      this.$refs.form.resetValidation();
    },
  },
};
</script>
